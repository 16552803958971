import React from 'react'
import Banner from '../../Components/reusable/Banner'
import Layout from '../../Components/reusable/Layout'
import MyCategory from "../../Components/product/category"
function Category() {
    return (
        <Layout>
            <Banner title="Categories" />
<section className="section my-5 py-5">
<div className="container">
    <div className="row my-5">
 

 <MyCategory
 title="Sprockets"
 content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
 Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
 next="view category"
 link="/products/subcategory"
  />

   <MyCategory
 title="Gears"
 content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
 Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
 next="view category"
 link="/products/subcategory"
  />

   <MyCategory
 title="Spline Shaft"
 content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
 Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
 next="view category"
 link="/products/subcategory"
 
  />
 {/* <div className="col-md-4 ">
     <div className="rs-icon-info-1" >
         <div className="info-icon">
             <i className="fas fa-building"></i>
         </div>
         <div className="info-text">
             <h4 className="text-black mb-2">Sprockets</h4>
             <p>Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
                 Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles.</p>
             <a href= "/" className="btn btn-secondary">view products</a>
         </div>
    </div>
</div> */}


 

        </div>

           </div>
       
       
</section>
        </Layout>
    )
}

export default Category
